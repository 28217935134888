// Note: This example requires that you consent to location sharing when
// prompted by your browser. If you see the error "The Geolocation service
// failed.", it means you probably did not give permission for the browser to
// locate you.


import React from 'react';
//import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import { createRoot } from 'react-dom/client';


/*
  TODO:
  - pick points on map
  -/ convert to fullscreen to fix mobile display problem (or put note to fullscreen the app)
  - add clues and codes as messages at end of each walk
    - a list of 32 bit binary numbers to XOR (monospace font?)
    - https://codebeautify.org/xor-calculator
  - add forced-order messages (displayed at end in the correct order, regardless of walkies completion order)
  -X modal dialog location tracking warning
  - add versioning to schema
  -X print QR code card
    - https://tassile.nfshost.com/walkies
  - deploy to prod
  
*/ 

const debug = false; // turns on/off ability to click and adding click event handlers

interface Stage {
  description: string,
  title: string,
  steps: {
    pos: google.maps.LatLngLiteral,
    icon: string,
    visited: boolean,
    completionText?: string,
  }[]
}

interface Metadata {
  version: number,
  trophiesFound: number,
}

const stageVersion:number = 3;  // increment this to force reloading of defaultStages on all browsers
const defaultMetadata:Metadata = {
  version: 1,
  trophiesFound: 0
}

const trophies:string[] = [
  "My little code-breaker, a puzzle for you. You'll probably need this number later: \
  0001010000001100",
  "This number also looks important. Perhaps there is a way to combine them? \
  1111000111010001",
  "Have you worked out that your combination lock doesn't have this many digits? \
  0010110011111010",
  "Add this one to your combination of clues. Then convert them! \
  1110011000010101",
  "This is your final clue. Now crack this code! \
  1101101010011101"
]


const defaultStages:Stage[] = [
  {
    "description": "your average walkies",
    "title": "walk 1",
    "steps": [
      {"pos": {lat: 38.88387759231776, lng:  -77.10773220967572}, "icon": "kiss_cat", "visited": false, "completionText": "Let's go! (1.7 mile loop)"},
      {"pos": {lat: 38.88739854330913, lng:  -77.10816022571248}, "icon": "kiss_cat", "visited": false},
      {"pos": {lat: 38.89011709668645, lng:  -77.10817710898529}, "icon": "kiss_cat", "visited": false},
      {"pos": {lat: 38.8910765889957, lng:  -77.10505930873376}, "icon": "kiss_cat", "visited": false},
      {"pos": {lat: 38.88956158662107, lng:  -77.10438781736414}, "icon": "trophy", "visited": false, "completionText": trophies[1]},
    ]
  },
  {
    "description": "a very quick jaunt",
    "title": "walk 2",
    "steps": [
      {"pos": {lat: 38.88226216754041, lng:  -77.10677552208729}, "icon": "snowflake", "visited": false, "completionText": "This is the short one! (0.7 mi loop)"},
      {"pos": {lat: 38.88372412082532, lng:  -77.10582916802342}, "icon": "snowflake", "visited": false},
      {"pos": {lat: 38.88495546086924, lng:  -77.1057762092221}, "icon": "snowflake", "visited": false},
      {"pos": {lat: 38.88567238847823, lng:  -77.10595120348931}, "icon": "snowflake", "visited": false},
      {"pos": {lat: 38.8860387479795, lng:  -77.10701836681736}, "icon": "snowflake", "visited": false},
      {"pos": {lat: 38.886001863227285, lng:  -77.1081111141776}, "icon": "snowflake", "visited": false},
      {"pos": {lat: 38.884267005884915, lng:  -77.10783067977805}, "icon": "snowflake", "visited": false},
      {"pos": {lat: 38.88434473905707, lng:  -77.10672857932714}, "icon": "snowflake", "visited": false},
      {"pos": {lat: 38.88408262870725, lng:  -77.10679667405797}, "icon": "trophy", "visited": false, "completionText": trophies[0]},
    ]
  },
  {
    "description": "a short walk",
    "title": "walk 3",
    "steps": [
      {"pos": {lat: 38.88128245794555, lng:  -77.106517010319}, "icon": "houses", "visited": false, "completionText": "Have fun! (1.2 mi loop)"},
      {"pos": {lat: 38.88174825386431, lng:  -77.10391820454252}, "icon": "houses", "visited": false},
      {"pos": {lat: 38.87968891422756, lng:  -77.10390766464472}, "icon": "houses", "visited": false},
      {"pos": {lat: 38.880510075854275, lng:  -77.10084013293087}, "icon": "houses", "visited": false},
      {"pos": {lat: 38.882016988435154, lng:  -77.10108995343131}, "icon": "trophy", "visited": false, "completionText": trophies[2]},
    ]
  },
  {
    "description": "a moderate walk",
    "title": "walk 4",
    "steps": [
      {"pos": {lat: 38.8811895092385, lng:  -77.10841017113164}, "icon": "sun", "visited": false, "completionText": "You've got this! (2.5 mi loop)"},
      {"pos": {lat: 38.88117367931448, lng:  -77.11216620012158}, "icon": "sun", "visited": false},
      {"pos": {lat: 38.880851364692, lng:  -77.11375179890246}, "icon": "sun", "visited": false},
      {"pos": {lat: 38.88111135257939, lng:  -77.11479375516979}, "icon": "sun", "visited": false},
      {"pos": {lat: 38.88214870169147, lng:  -77.11844007090063}, "icon": "sun", "visited": false},
      {"pos": {lat: 38.8831253814563, lng:  -77.12094620073341}, "icon": "sun", "visited": false},
      {"pos": {lat: 38.88559970591206, lng:  -77.11917269159329}, "icon": "sun", "visited": false},
      {"pos": {lat: 38.8871053569838, lng:  -77.12105869907224}, "icon": "trophy", "visited": false, "completionText": trophies[3]},
    ]
  },
  {
    "description": "A long urban hike!",
    "title": "walk 5",
    "steps": [
      {"pos": {lat: 38.87812485803133, lng:  -77.10723740636304}, "icon": "sweaty_face", "visited": false, "completionText": "Your longest walkies! (4.4 mi loop)"},
      {"pos": {lat: 38.873477490731965, lng:  -77.1112214723694}, "icon": "sweaty_face", "visited": false},
      {"pos": {lat: 38.872962333234454, lng:  -77.11572672645056}, "icon": "sweaty_face", "visited": false},
      {"pos": {lat: 38.870309112543595, lng:  -77.11729328209842}, "icon": "sweaty_face", "visited": false},
      {"pos": {lat: 38.86690692939246, lng:  -77.11988600711771}, "icon": "sweaty_face", "visited": false},
      {"pos": {lat: 38.867010011403465, lng:  -77.12311606111386}, "icon": "sweaty_face", "visited": false},
      {"pos": {lat: 38.866876160923916, lng:  -77.12499633280153}, "icon": "sweaty_face", "visited": false},
      {"pos": {lat: 38.86837923843489, lng:  -77.12900094720348}, "icon": "sweaty_face", "visited": false},
      {"pos": {lat: 38.869735929802154, lng:  -77.13201633281706}, "icon": "sweaty_face", "visited": false},
      {"pos": {lat: 38.87170613762675, lng:  -77.13253501255382}, "icon": "sweaty_face", "visited": false},
      {"pos": {lat: 38.87094021046328, lng:  -77.12823396863915}, "icon": "sweaty_face", "visited": false},
      {"pos": {lat: 38.87461293372912, lng:  -77.12418687087676}, "icon": "sweaty_face", "visited": false},
      {"pos": {lat: 38.87810948599827, lng:  -77.12105056113028}, "icon": "sweaty_face", "visited": false},
      {"pos": {lat: 38.878588209906724, lng:  -77.12061494509659}, "icon": "trophy", "visited": false, "completionText": trophies[4]},
    ]
  }
]


let map: google.maps.Map, infoWindow: google.maps.InfoWindow;
let defaultUserLocation: google.maps.LatLngLiteral = {lat: 38.8836933, lng: -77.1192139};
const proximityMeters = 40;
const proximity_gcs = proximityMeters * 0.000009009; // 0.00001 = 1.11 meters 0.00001 0.000009009 = 1m


const icons = {
  'user': ['images/bunny.png', 'images/bunny_bw.png'],
  'houses': ['images/houses.png', 'images/houses_bw.png'],
  'kiss_cat': ['images/kiss_cat.png', 'images/kiss_cat_bw.png'],
  'snowflake': ['images/snowflake.png', 'images/snowflake_bw.png'],
  'sun': ['images/sun.png', 'images/sun_bw.png'],
  'sweaty_face': ['images/sweaty_face.png', 'images/sweaty_face_bw.png'],
  'trophy': ['images/trophy.png', 'images/trophy_bw.png']
};
const storageKey:string = 'walkies_store';
const metadataKey:string = 'walkies_meta';

let markers:google.maps.Marker[] = [];
let circles:google.maps.Circle[] = [];
let store:[] = [];
let stages:Stage[];
let metadata:Metadata;
let user: google.maps.Marker;


// https://stackoverflow.com/questions/67700374/use-localstorage-getitem-with-typescript
function loadState(){
  store = JSON.parse(localStorage.getItem(storageKey) || '{}');
  metadata = JSON.parse(localStorage.getItem(metadataKey)) || defaultMetadata;
  if(!store['stages'] || stageVersion > metadata['version']){ // load defaults instead
    console.log('first time visitor or new version? Loading defaults...')
    store['stages'] = defaultStages;
    metadata['version'] = stageVersion;
  }
  stages = store['stages'];
}


function saveState(){
  console.log('saving state...')
  localStorage.setItem(storageKey, JSON.stringify(store));
  localStorage.setItem(metadataKey, JSON.stringify(metadata));
}


function dist(a:google.maps.LatLngLiteral, b:google.maps.LatLngLiteral){
  if(debug){
    // console.log('distance check with')
    // console.log(`${a.lat}, ${a.lng} : ${b.lat}, ${b.lng}`)
  }
  return Math.sqrt((a.lat - b.lat)**2 + (a.lng - b.lng)**2)
}


function markStepsAsVisited(location:google.maps.LatLngLiteral){
  let updates:boolean = false;
  for(let stage of stages){
    for(let step of stage['steps']){
      if( !step['visited'] && dist(location, step['pos']) <= proximity_gcs  ) { 
        console.log('just visited!')
        step['visited'] = true;
        updates = true
        saveState();
      }
    }
  }
  return updates;
}


function displayMarkersForSteps(){
  // remove all prior markers
  for(let marker of markers){
    marker.setMap(null);
  }
  markers = [];

  // remove all prior circles
  for(let circle of circles){
    circle.setMap(null);
  }
  circles = [];

  // displays all markers in 'steps' array on current map
  for(let stage of stages){
    for(let step of stage['steps']){
      // use b/w (second image) if visited
      let chosenIcon = step['visited'] ? icons[step['icon']][1] : icons[step['icon']][0];
      // explicitly set the text to undefined if we have not visited the node, to only display the text as relevant
      let chosenText = step['visited'] ? step['completionText'] : undefined;
      let marker = addMarker(step['pos'], chosenIcon, chosenText);

      markers.push(marker);
      console.log(marker);
      if( !step['visited'] ){
        // bounce this next step
        marker.setAnimation(google.maps.Animation.BOUNCE);  // marker.setAnimation(null);
        // and make a circle for it
        circles.push(addCircle(step['pos'], proximityMeters))
        break;  // do not render further steps
      }
    }
  }
}


function addMarker(pos: google.maps.LatLngLiteral, image:string, text?:string) {  // , map: google.maps.Map
  if(text){
    let smallInfo = new google.maps.InfoWindow();
    smallInfo.setPosition(pos);
    smallInfo.setContent(text);
    smallInfo.open(map);
  }

  // Adds a marker to the map
  const marker = new google.maps.Marker({
    position: pos,
    map,
    icon: image
  });

  return marker;
}


function addCircle(location:google.maps.LatLngLiteral, radius:number){
  // adds a circle to the map
  const circle = new google.maps.Circle({
    strokeColor: "#00FF00",
    strokeOpacity: 0.5,
    strokeWeight: 2,
    fillColor: "#00FF00",
    fillOpacity: 0.25,
    map,
    center: location,
    radius: radius,
    draggable: false
  });
  if(debug){
    circle.addListener('click', handlePlaceClick);  // TODO: REMOVE ME
  }
  return circle
}


function configureUserMarker(defaultLocation: google.maps.LatLngLiteral){
  user = new google.maps.Marker({
    position: defaultLocation,
    icon: icons['user'][0],
    map: map,
  });
}


function updateUserMarker(location: google.maps.LatLngLiteral){
  user.setPosition(location);
}


const locationOptions = {
  enableHighAccuracy: true,
  timeout: 3000,
};

function updateLocation(){
  if(debug){
    console.log("position change callback")
  }
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        if(debug){ console.log(pos); }

        // update user's position
        updateUserMarker(pos);
        let anyVisited:boolean = markStepsAsVisited(pos);
        if(anyVisited){
          displayMarkersForSteps(); 
        }
      },
      () => { handleLocationError(true, infoWindow, map.getCenter()!); },
      locationOptions
    );
  } else {
    // Browser doesn't support Geolocation
    handleLocationError(false, infoWindow, map.getCenter()!);
  }
}
  
  
function initMap(): void {
  map = new google.maps.Map(
    document.getElementById("map") as HTMLElement,
    {
      mapId: "6f349b0a663b10a1",
      center: defaultUserLocation,
      zoom: 14,
      controlSize: 50,
    } as google.maps.MapOptions
  );

  if(debug){
    map.addListener('click', handlePlaceClick);
  }

  infoWindow = new google.maps.InfoWindow();

  configureUserMarker(defaultUserLocation);

  const locationButton = document.createElement("button");
  locationButton.textContent = "Pan to Current Location";
  locationButton.classList.add("custom-map-control-button");
  map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(locationButton);
  locationButton.addEventListener("click", goToCurrentPosition);

  loadState();
  displayMarkersForSteps();
}


function goToCurrentPosition(){
  console.log("Navigating to current position.")
  // Try HTML5 geolocation.
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        map.panTo(pos);
      },
      () => {
        handleLocationError(true, infoWindow, map.getCenter()!);
      }
    );
  } else {
    // Browser doesn't support Geolocation
    console.log("Browser not currently supporting Geolocation")
    handleLocationError(false, infoWindow, map.getCenter()!);
  }
} 


async function handlePlaceClick(event) {
  // let feature = event.features[0];
  console.log(event.latLng);
  let pos = {"lat": event.latLng.lat(), "lng": event.latLng.lng()} // event.latLng
  updateUserMarker(pos);
  let anyVisited:boolean = markStepsAsVisited(pos);
  if(anyVisited){
    displayMarkersForSteps(); 
  }
}


function handleLocationError(
    browserHasGeolocation: boolean,
    infoWindow: google.maps.InfoWindow,
    pos: google.maps.LatLng
  ) {
    console.log("Error: The Geolocation service failed.");
  // infoWindow.setPosition(pos);
  // infoWindow.setContent(
  //   browserHasGeolocation
  //     ? "Error: The Geolocation service failed."
  //     : "Error: Your browser doesn't support geolocation."
  // );
  // infoWindow.open(map);
}


declare global {
  interface Window {
    initMap: () => void;
  }
}

window.initMap = initMap;
export {};


ReactModal.setAppElement('#main');

interface State {
  showModal: boolean
};

class App extends React.Component {
  state:State={showModal: true};

  constructor (props) {
    super(props);
    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  
  handleOpenModal () {
    this.setState({ showModal: true });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
    // instead of polling, watch for position changes and use callbacks
    navigator.geolocation.watchPosition(updateLocation)

  }
  
  render () {
    return (
      <div>
        {/* <button onClick={this.handleOpenModal}>Trigger Modal</button> */}

        <ReactModal 
           isOpen={this.state.showModal}
           contentLabel="onRequestClose Example"
           onRequestClose={this.handleCloseModal}
           shouldCloseOnOverlayClick={false}
           style={customStyles}
        >
          <div id="message">
            <p>Welcome to walkies, my ❄️🐰!</p>
            <p>This app only works if you accept location tracking</p>
            <p>Enjoy!</p>
            <p> -Your 🔥🦊</p>
          
            <button onClick={this.handleCloseModal}>Click this button to start!</button>
          </div>
        </ReactModal>

      </div>
    );
  }
}

const props = {};

const container = document.getElementById('main');
const root = createRoot(container!);
root.render(<App {...props} />);

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#E1E1E1',
    borderColor: '#979797'
  },
};
